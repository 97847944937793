import { v4 as uuidv4 } from 'uuid';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('auth', ['_id'])
  },
  methods: {
    generateSocketId(socketEvent) {
      const shortUuid = uuidv4()
        .replace(/[^a-zA-Z0-9]/g, '')
        .slice(0, 9);
      return `${this._id}-${shortUuid}-${socketEvent}`;
    }
  }
};
