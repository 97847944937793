var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-content-between"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-md-start justify-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"d-flex flex-row justify-start align-center"},[_c('h2',[_vm._v(_vm._s(_vm.$t("Payroll")))]),_c('v-tooltip',{attrs:{"top":"","max-width":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6 color-gray-text",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("tooltipUsers1"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("tooltipUsers2"))+" ")])])],1)])],1),_c('v-row',{staticClass:"mt-4 d-flex flex-row justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Search'),"prepend-inner-icon":_vm.icons.mdiMagnify,"outlined":"","dense":"","clearable":"","disabled":_vm.table.tableLoading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.search = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"md":"7","cols":"12"}},[_c('v-select',{attrs:{"label":"Status","outlined":"","dense":"","multiple":"","items":_vm.computedItemsSelectionFilter,"value":_vm.selectionFilter,"disabled":_vm.table.tableLoading},on:{"input":_vm.updateSelectionFilter},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item === 'Activo' || item === 'Active')?_c('v-chip',{staticClass:"v-chip-light-bg success--text",attrs:{"color":"accent","close":""},on:{"click:close":function($event){return _vm.remove(item)}}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(item === 'Inactivo' || item === 'Inactive')?_c('v-chip',{staticClass:"v-chip-light-bg error--text",attrs:{"color":"accent","close":""},on:{"click:close":function($event){return _vm.remove(item)}}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(
                        item === 'Esperando aprobación' ||
                        item === 'Waiting for approval'
                      )?_c('v-chip',{staticClass:"v-chip-light-bg warning--text",attrs:{"color":"warning","close":""},on:{"click:close":function($event){return _vm.remove(item)}}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e()]}},{key:"item",fn:function(ref){
                      var active = ref.active;
                      var item = ref.item;
                      var attrs = ref.attrs;
                      var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[(item === 'Activo' || item === 'Active')?_c('v-chip',{staticClass:"v-chip-light-bg success--text cursor-hover",attrs:{"color":"accent"}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(item === 'Inactivo' || item === 'Inactive')?_c('v-chip',{staticClass:"v-chip-light-bg error--text cursor-hover",attrs:{"color":"accent"}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(
                              item === 'Esperando aprobación' ||
                              item === 'Waiting for approval'
                            )?_c('v-chip',{staticClass:"v-chip-light-bg warning--text cursor-hover",attrs:{"color":"warning"}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e()],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}])})],1)],1)],1),_c('v-col',{staticClass:"d-flex flex-row justify-end align-start",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"block":"","outlined":"","color":"primary","disabled":_vm.table.tableLoading},on:{"click":function($event){return _vm.handleExport()}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiDownload))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t("Export")))])],1)],1)],1),_c('v-row',{staticClass:"mt-4",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.new_)?_c('DataTable',{attrs:{"loading":_vm.table.tableLoading,"headers":_vm.table.headers,"items":_vm.computedFilteredUsers,"search":_vm.search,"selectionFilter":_vm.selectionFilter,"refresher":_vm.refresher},on:{"refresh":function($event){return _vm.handleRefresh()}}}):_vm._e()],1)],1)],1)],1)],1),(_vm.new_)?_c('v-row',{staticClass:"custom-margin"},[_c('v-col',{staticClass:"d-flex flex-column justify-center align-center",attrs:{"cols":"12"}},[(!_vm.$vuetify.theme.isDark)?_c('v-img',{attrs:{"src":require("../Users/assets/typography_es.png"),"width":"300"}}):_c('v-img',{attrs:{"src":require("../Users/assets/typography_es_dark.png"),"width":"300"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row justify-md-end justify-center align-center",attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"#ffffff !important"}},[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t("Add to payroll")))])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleModal()}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("bulk.unitary")))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleBulkAddusersModal()}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("bulk.bulk")))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }