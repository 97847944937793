<template>
  <BaseDialog
    :loading="loading"
    :loadingSubmit="loadingSubmit"
    :catastrophicError="catastrophicError"
    @closed="handleClose()"
    :dialogTitle="dialogTitle"
    :persistentDialog="step !== 3"
    :errorMessage="errorMessage"
    :closeMessage="closeMessage"
  >
    <template #content>
      <template v-if="step === 1">
        <v-row class="mt-6">
          <v-col>
            <v-progress-linear
              :value="progress"
              color="primary"
              height="40"
              rounded
            >
              <div class="text-center white--text">{{ progress }}%</div>
            </v-progress-linear>
            <div class="text-center mt-3">
              {{ processingMessage }} <b>{{ currentPage }}</b>
              {{ ofMessage }}
              <b>{{ totalPages }}</b>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-if="step === 2">
        <v-row class="fill-height ma-0">
          <v-col cols="12" class="d-flex flex-row justify-center align-center">
            <v-progress-circular
              indeterminate
              color="primary"
              size="64"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex flex-row justify-center align-center">
            {{ processingPageMessage }}
          </v-col>
        </v-row>
      </template>

      <template v-if="step === 3">
        <v-row>
          <v-col cols="12" class="d-flex flex-row justify-center align-center">
            <span style="font-size: 1.2rem" v-if="Boolean(progress)">
              {{ youCanDownloadMessage }}
            </span>
            <span v-else style="font-size: 1.2rem">
              {{ exportFileHasExpiredMessage }}
              <b>{{ expiredMessage }}</b>
            </span>
          </v-col>
        </v-row>
        <!-- <v-row>
            <v-col cols="12" class="d-flex flex-row justify-center align-center">
              <pre>{{ fileURL }}</pre>
            </v-col>
          </v-row> -->
        <v-row class="d-flex flex-row justify-center align-center" dense>
          <v-col cols="4" class="d-flex flex-row justify-center align-center">
            <v-btn
              v-if="Boolean(progress)"
              :href="fileURL"
              link
              target="_blank"
              color="primary"
              block
              rounded
            >
              {{ downloadMessage }}
              <span class="ml-1">
                <v-progress-circular
                  :value="progress"
                  color="white"
                  size="20"
                  :width="3"
                  rounded
                >
                </v-progress-circular>
              </span>
            </v-btn>
            <v-btn
              v-else
              @click="handleClose"
              color="primary"
              block
              rounded
              outlined
            >
              {{ goBackMessage }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          class="d-flex flex-row justify-center align-center mt-n2"
          dense
          v-if="false && Boolean(progress)"
        >
          <v-col cols="4" class="d-flex flex-row justify-center align-center">
            <v-progress-circular
              :value="progress"
              color="primary"
              height="10"
              class="ma-2"
              rounded
            >
            </v-progress-circular>
          </v-col>
        </v-row>
      </template>
    </template>
  </BaseDialog>
</template>
<script>
import BaseDialog from "@/components/BaseDialog.vue";
export default {
  name: "ExportSocketDialog",
  components: {
    BaseDialog,
  },
  props: {
    payload: Object,
    required: true,
    dialogTitle: {
      type: String,
      required: true,
    },
    processingMessage: {
      type: String,
      required: true,
    },
    ofMessage: {
      type: String,
      required: true,
    },
    downloadMessage: {
      type: String,
      required: true,
    },
    goBackMessage: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      required: true,
    },
    closeMessage: {
      type: String,
      required: true,
    },
    youCanDownloadMessage: {
      type: String,
      required: true,
    },
    processingPageMessage: {
      type: String,
      required: true,
    },
    exportFileHasExpiredMessage: {
      type: String,
      required: true,
    },
    expiredMessage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      catastrophicError: false,
      loadingSubmit: false,
      currentPage: 0,
      totalPages: 0,
      progress: 0,
      step: 1,
      fileURL: "",
      progress: 0,
      interval: null,
    };
  },
  methods: {
    handleClose() {
      if (this.loadingSubmit) {
        return;
      }
      this.$emit("submit", "closed");
    },
    handleSocke$t() {
      this.loading = true;
      try {
        this.sockets.subscribe(
          this.payload.socketId,
          this.handleSocketResponse
        );
      } catch (error) {
        console.log("🚩🚩🚩- DM ~ handleSocket ~ error:", error);
        this.handleError();
        this.catastrophicError = true;
      } finally {
        this.loading = false;
      }
    },
    handleSocketResponse(data) {
      try {
        const { status } = data;
        if (!status) return;

        switch (status) {
          case "processingPages":
            this.handleProcessingPages(data);
            break;

          case "processedForExcelPayload":
            this.step = 2;
            break;

          case "exportSuccess":
            this.handleExportSuccess(data);
            break;

          case "error":
            this.handleError();
            break;

          default:
            console.warn(`Unhandled status: ${status}`);
        }
      } catch (error) {
        console.error("🚩🚩🚩- DM ~ handleSocket ~ error:", error);
        this.handleError();
        this.catastrophicError = true;
      }
    },

    handleProcessingPages(data) {
      const { currentPage, totalPages, progress } = data;

      this.step = 1;
      this.currentPage = currentPage;
      this.totalPages = totalPages;
      this.progress = parseFloat(progress);
    },

    handleExportSuccess(data) {
      const { fileURL, delayForDeletingFile } = data;

      this.step = 3;
      this.fileURL = fileURL;
      this.startProgressBar(delayForDeletingFile);
    },

    handleError() {
      this.step = null;
      this.catastrophicError = true;
    },

    startProgressBar(delay) {
      const totalMilliseconds = delay;
      let remainingMilliseconds = totalMilliseconds;

      this.progress = 0;

      this.interval = setInterval(() => {
        remainingMilliseconds -= 100;
        this.progress = (remainingMilliseconds / totalMilliseconds) * 100;

        if (remainingMilliseconds <= 0) {
          clearInterval(this.interval);
          this.progress = 0;
        }
      }, 100);
    },
  },
  created() {
    this.handleSocke$t();
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>
<style lang="scss" scoped></style>
