<template>
  <v-card tile class="px-4 pt-4 pb-8 dialog-container">
    <v-container
      fluid
      class="d-flex flex-column align-center ma-0 pa-4"
      :class="{
        'justify-space-between': !loading && !catastrophicError,
        'justify-center': loading || catastrophicError,
      }"
      style="height: 100%; min-height: 200px"
    >
      <div style="width: 100%">
        <template v-if="!loading">
          <template v-if="!catastrophicError">
            <!-- titulo -->
            <template v-if="defaultFirstRow">
              <v-row dense>
                <v-col cols="9">
                  <h2>{{ dialogTitle }}</h2>
                </v-col>
                <v-col
                  cols="3"
                  class="d-flex flex-row justify-end"
                  v-if="!persistentDialog"
                >
                  <v-btn @click="handleClose" icon :disabled="loadingSubmit">
                    <v-icon color="primary" large>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense v-if="Boolean(dialogDescription)">
                <v-col cols="12">
                  <p>{{ dialogDescription }}</p>
                </v-col>
              </v-row>
            </template>

            <slot name="content"> </slot>
          </template>
          <template v-if="catastrophicError">
            <template v-if="!catastrophicErrorSlot">
              <v-row>
                <v-col class="d-flex flex-row justify-center">
                  <h1>{{ errorMessage }}</h1>
                </v-col>
              </v-row>
              <br />
              <v-row>
                <v-col class="d-flex flex-row justify-center">
                  <v-btn color="primary" @click="handleClose" rounded>
                    {{ closeMessage }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-if="catastrophicErrorSlot">
              <slot name="catastrophicErrorSlot"></slot>
            </template>
          </template>
        </template>

        <template v-if="loading">
          <template v-if="!loadingSlot">
            <v-row>
              <v-col class="d-flex flex-row justify-center">
                <v-progress-circular
                  indeterminate
                  :size="128"
                  color="primary"
                />
              </v-col>
            </v-row>
          </template>

          <template v-if="loadingSlot">
            <slot name="loadingSlot"></slot>
          </template>
        </template>
      </div>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "BaseDialog",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    catastrophicError: {
      type: Boolean,
      default: false,
    },
    loadingSlot: {
      type: Boolean,
      default: false,
    },
    catastrophicErrorSlot: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: "dialogTitle",
    },
    defaultFirstRow: {
      type: Boolean,
      default: true,
    },
    dialogDescription: {
      type: String,
      default: "",
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    persistentDialog: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "Sorry, there was an error",
    },
    closeMessage: {
      type: String,
      default: "Close",
    },
  },
  methods: {
    handleClose() {
      if (this.loadingSubmit) {
        return;
      }
      this.$emit("closed");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen {
  .v-card.v-sheet.theme--light.rounded-0,
  .v-card.v-sheet.theme--dark.rounded-0 {
    min-height: 100vh !important;
  }
}

.dialog-container {
  min-height: 200px;
}
</style>
