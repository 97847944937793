<template>
  <v-card tile class="">
    <!-- <v-card-title class="text-h5 primary mb-2">
      <span class="white--text">
        Notificación
      </span>
    </v-card-title> -->

    <v-card-text class="text-center">
      <v-container class="pa-12">
        <v-row dense class="d-flex flex-row justify-center align-center">
          <v-col cols="12">
            <h2>Delete user</h2>
          </v-col>
          <v-col cols="12">
            <p class="">Would you like to delete the following user?</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :disable-sort="!false"
              :items="item"
              class="dataTable"
              :hide-default-footer="true"
            >
              <!-- <template #item="{ item }">
        <tr>
          <td v-for="(cell, i) in item" :key="i">
            <span v-if="i !== 10">
              {{ cell }}
            </span>
            <span v-else>

            </span>
          </td>
        </tr>
      </template> -->
              <template #item.balance="{ item }">
                <span
                  v-if="item.balance !== '--'"
                  :class="tableCellBalance(item.balance)"
                >
                  {{ item.balance | currency }}</span
                >
                <span v-else> {{ item.balance }} </span>
              </template>
              <template v-slot:item.status="{ value }">
                <v-chip
                  v-if="value === 'Active'"
                  class="v-chip-light-bg success--text"
                  color="accent"
                >
                  Active
                </v-chip>
                <v-chip
                  v-if="value === 'Inactive'"
                  class="v-chip-light-bg error--text"
                  color="accent"
                >
                  Inactive
                </v-chip>
                <v-chip
                  v-if="value === 'Waiting for approval'"
                  class="v-chip-light-bg warning--text"
                  color="warning"
                >
                  Waiting for approval
                </v-chip>
              </template>
              <template v-slot:item.overdraft="{ value }">
                <v-chip
                  v-if="value === true"
                  class="v-chip-light-bg success--text"
                  color="accent"
                >
                  Active
                </v-chip>
                <v-chip
                  v-if="value === false"
                  class="v-chip-light-bg error--text"
                  color="accent"
                >
                  Inactive
                </v-chip>
                <span v-if="value === '--'"> -- </span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-menu rounded=".rounded-lg" offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <!-- class="white--text ma-5" -->
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon>
                        {{ icons.mdiDotsVertical }}
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item link>
                      <v-list-item-title>Cargar Saldo</v-list-item-title>
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title>{{
                        item.overdraft
                          ? "Desactivar Sobregiro"
                          : "Activar Sobregiro"
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title @click="toggleDeleteModal(item)"
                        >Eliminar Usuario</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <!-- <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize"> Reset </v-btn>
                </template> -->
            </v-data-table>
          </v-col>
        </v-row>
        <v-row
          class="
            d-flex
            flex-md-row flex-column-reverse
            justify-center
            align-center
          "
        >
          <v-col cols="12" md="4">
            <v-btn color="primary" block outlined @click="handleClose()">
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn color="primary" block @click="handleDelete()">
              Accept
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiWhatsapp } from "@mdi/js";
import { mdiMagnify, mdiAlert } from "@mdi/js";
import { VMoney } from "v-money";
import axios from "@/plugins/axios";
import { mapActions, mapState } from "vuex";

export default {
  directives: { money: VMoney },
  props: {
    item: {
      type: Array,
    },
  },
  data() {
    return {
      icons: {
        mdiWhatsapp,
        mdiMagnify,
        mdiAlert,
      },
      alertError: false,
      price: 0,
      money: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      headers: [
        { text: "ID USUARIO", value: "unique_id", align: "start" },
        { text: "NOMBRE", value: "full_name", align: "start" },
        { text: "TELÉFONO", value: "phone", align: "start" },
        { text: "CORREO", value: "email", align: "start" },
        { text: "SALDO", value: "balance", align: "start" },
        { text: "STATUS", value: "status", align: "start" },
        { text: "SOBREGIRO", value: "overdraft", align: "start" },
      ],
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    ...mapActions("auth", ["me"]),
    handleClose() {
      this.$emit("submit", null);
    },
    async handleDelete() {
      this.setLoading(true);
      try {
        const { data } = await axios.post(
          `${process.env.VUE_APP_STAGING}/v2/corporate/remove_user`,
          {
            userIds: [this.item[0]._id],
          }
        );
        if (!!data.success) {
          this.$dialog.notify.success(
            `${this.item[0].full_name} has been eliminated`
          );
          this.item[0].balance > 0 &&
            this.$dialog.notify.success(
              `$${this.item[0].balance} has been incorporated to the corporate balance`
            );
          this.$emit("submit", true);
        } else {
          throw new Error("Error deleting");
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    tableCellBalance(value) {
      if (value > 0) {
        return `color-green-text`;
      }
      if (value < 0) {
        return `color-red-text`;
      }
      return ``;
    },
  },
};
</script>
<style lang="sass" scoped>
.vuedl-layout__closeBtn
    // color: white
    // opacity: 1
    color: rgba(58, 53, 65, 0.54)
    // margin-top: 8px

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    height: 100vh

a
  text-decoration: none

// .custom-margin
  // margin-top: -10px !important

// .test.v-btn:not(.v-btn--round).v-size--x-large
//   height: 55.22px !important

.custom-width
  width: 46%

.custom-margin-top
  margin-top: -10px
</style>
