<template>
  <v-card tile class="">
    <template v-if="step === 1">
      <v-card-text class="text-center">
        <v-container class="pa-12">
          <v-row dense class="d-flex flex-row justify-center align-center">
            <v-col cols="12">
              <h2>
                {{
                  ES
                    ? "Operaciones masivas: sobregiro"
                    : "Bulk operations: overdraft"
                }}
              </h2>
            </v-col>
            <v-col cols="12">
              <p class="" v-if="ES">
                ¿Desea activar o desactivar sobregiro a los siguientes usuarios?
              </p>
              <p v-else>
                ¿Would you like to activate o deactivate overdraft to the
                following users?
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="ES ? headersES : headersEN"
                :disable-sort="false"
                :items="item"
                class="dataTable"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
                }"
                mobile-breakpoint="0"
                :dense="true"
              >
                <template #item.balance="{ item }">
                  <span
                    v-if="item.balance !== '--'"
                    :class="tableCellBalance(item.balance)"
                  >
                    {{ item.balance | currency }}</span
                  >
                  <span v-else> {{ item.balance }} </span>
                </template>
                <template v-slot:item.status="{ value }">
                  <v-chip
                    v-if="value === 'Active' || value === 'Activo'"
                    class="v-chip-light-bg success--text"
                    color="accent"
                    small
                  >
                    {{ ES ? "Activo" : "Active" }}
                  </v-chip>
                </template>
                <template v-slot:item.overdraft="{ value }">
                  <v-chip
                    v-if="value === true"
                    class="v-chip-light-bg success--text"
                    color="accent"
                    small
                  >
                    {{ ES ? "Activo" : "Active" }}
                  </v-chip>
                  <v-chip
                    v-if="value === false"
                    class="v-chip-light-bg error--text"
                    color="accent"
                    small
                  >
                    {{ ES ? "Inactivo" : "Inactive" }}
                  </v-chip>
                  <span v-if="value === '--'"> -- </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <h2>
                {{
                  ES
                    ? "Activar o desactivar sobregiro"
                    : "Activate or deactivate overdraft"
                }}
              </h2>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row justify-center align-center">
            <v-col cols="4">
              <v-btn block color="error" @click="handleStep1(false)">
                {{ ES ? "Desactivar" : "Deactivate" }}</v-btn
              >
            </v-col>
            <v-col cols="4"
              ><v-btn block color="success" @click="handleStep1(true)">
                {{ ES ? "Activar" : "Activate" }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row
            class="
              d-flex
              flex-md-row flex-column-reverse
              justify-center
              align-center
            "
          >
            <v-col cols="12" md="12">
              <a
                class="text-decoration-underline text-lg"
                @click="handleClose()"
              >
                {{ ES ? "Cancelar" : "Cancel" }}
              </a>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </template>
    <template v-if="step === 2">
      <v-card-text class="text-center">
        <v-container class="pa-12">
          <template v-if="loading">
            <v-row>
              <v-col>
                <v-progress-circular
                  class="mt-10 mb-10"
                  indeterminate
                  :size="64"
                  color="primary"
                />
                <p class="text-xl">{{ ES ? "Enviando..." : "Sending..." }}</p>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row dense class="d-flex flex-row justify-center align-center">
              <v-col cols="12">
                <h2>
                  {{
                    ES
                      ? "Operaciones masivas: sobregiro"
                      : "Bulk operations: overdraft"
                  }}
                </h2>
              </v-col>
            </v-row>
            <template v-if="!successRequest">
              <v-row>
                <v-col cols="12" class="d-flex flex-column align-center">
                  <v-img
                    src="@/views/Balance/assets/notFound.png"
                    width="250"
                    class="wobble-hor-bottom"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <br />
              <h2>{{ ES ? "¡Lo sentimos!" : "We are sorry!" }}</h2>
              <h3>
                {{
                  ES
                    ? "Algo sucedió, por favor intentar de nuevo"
                    : "Something happened, please try again"
                }}
              </h3>
              <br />
              <v-row>
                <v-col cols="12">
                  <v-btn color="primary" outlined @click="step--">
                    {{ ES ? "Volver" : "Go back" }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-if="successRequest">
              <v-row>
                <v-col cols="12" class="d-flex flex-column align-center">
                  <v-img
                    src="@/views/Balance/assets/found.png"
                    width="250"
                    class="wobble-hor-bottom"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <br />
              <h2>{{ ES ? "¡Éxito!" : "Success!" }}</h2>
              <h3>{{ ES ? "Sobregiro actualizado" : "Overdraft updated" }}</h3>
              <br />
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="ES ? headersES : headersEN"
                    :disable-sort="false"
                    :items="usersSuccess"
                    class="dataTable"
                    :footer-props="{
                      itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
                    }"
                    mobile-breakpoint="0"
                    :dense="true"
                  >
                    <template #item.balance="{ item }">
                      <span
                        v-if="item.balance !== '--'"
                        :class="tableCellBalance(item.balance)"
                      >
                        {{ item.balance | currency }}</span
                      >
                      <span v-else> {{ item.balance }} </span>
                    </template>
                    <template v-slot:item.status="{ value }">
                      <v-chip
                        v-if="value === 'Active' || value === 'Activo'"
                        class="v-chip-light-bg success--text"
                        color="accent"
                        small
                      >
                        {{ ES ? "Activo" : "Active" }}
                      </v-chip>
                    </template>
                    <template v-slot:item.overdraft="{ value }">
                      <v-chip
                        v-if="value === true"
                        class="v-chip-light-bg success--text"
                        color="accent"
                        small
                      >
                        {{ ES ? "Activo" : "Active" }}
                      </v-chip>
                      <v-chip
                        v-if="value === false"
                        class="v-chip-light-bg error--text"
                        color="accent"
                        small
                      >
                        {{ ES ? "Inactivo" : "Inactive" }}
                      </v-chip>
                      <span v-if="value === '--'"> -- </span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn color="primary" outlined @click="handleCloseSuccess()">
                    {{ ES ? "Volver" : "Go back" }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </template>
        </v-container>
      </v-card-text>
    </template>
  </v-card>
</template>
<script>
import { mdiWhatsapp } from "@mdi/js";
import { mdiMagnify, mdiAlert } from "@mdi/js";
import { VMoney } from "v-money";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";

export default {
  directives: { money: VMoney },
  props: {
    item: {
      type: Array,
    },
  },
  data() {
    return {
      icons: {
        mdiWhatsapp,
        mdiMagnify,
        mdiAlert,
      },
      headersEN: [
        { text: "USER ID", value: "unique_id", align: "start" },
        { text: "NAME", value: "full_name", align: "start" },
        { text: "PHONE", value: "phone", align: "start" },
        { text: "EMAIL", value: "email", align: "start" },
        { text: "BALANCE", value: "balance", align: "start" },
        { text: "STATUS", value: "status", align: "start" },
        { text: "OVERDRAFT", value: "overdraft", align: "start" },
      ],
      headersES: [
        { text: "ID USUARIO", value: "unique_id", align: "start" },
        { text: "NOMBRE", value: "full_name", align: "start" },
        { text: "TELÉFONO", value: "phone", align: "start" },
        { text: "CORREO", value: "email", align: "start" },
        { text: "SALDO", value: "balance", align: "start" },
        { text: "STATUS", value: "status", align: "start" },
        { text: "SOBREGIRO", value: "overdraft", align: "start" },
      ],
      overdraftActivate: undefined,
      step: 1,
      loading: false,
      successRequest: false,
      usersSuccess: [],
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    ...mapActions("auth", ["me"]),
    handleClose() {
      this.$emit("submit", null);
    },
    handleCloseSuccess() {
      this.$emit("submit", true);
    },
    tableCellBalance(value) {
      if (value > 0) {
        return `color-green-text`;
      }
      if (value < 0) {
        return `color-red-text`;
      }
      return ``;
    },
    async handleStep1(activate) {
      activate
        ? (this.overdraftActivate = true)
        : (this.overdraftActivate = false);
      this.step++;
      this.loading = true;
      let ids = [];
      this.item.forEach((el) => {
        ids.push(el._id);
      });
      try {
        const params = {
          userIds: ids,
          is_allow_overdraft: this.overdraftActivate,
        };
        const { data } = await axios.post(
          `${process.env.VUE_APP_STAGING}/v2/corporate/change_overdraft`,
          params
        );
        if (!!data.success) {
          this.successRequest = true;
          this.usersSuccess = [];
          data.data.users.forEach((user) => {
            let obj = {
              unique_id: user.unique_id,
              full_name: `${user.first_name} ${user.last_name}`,
              picture: user.picture,
              initials: `${user.first_name[0]}${user.last_name[0]}`,
              phone: `${user.country_phone_code}${user.phone}`,
              email: user.email,
              balance: user.corporate_wallet_limit,
              status: this.handleUserStatus(user, data.data.corporate_id),
              overdraft: user.is_allow_overdraft ? true : false,
              _id: user._id,
            };
            this.usersSuccess.push(obj);
          });
        } else {
          throw data.error_code;
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      } finally {
        this.loading = false;
      }
    },
    handleUserStatus(user, corporate_id) {
      // if (
      //   user.corporate_ids &&
      //   user.corporate_ids.length > 0 &&
      //   user.corporate_ids[0].corporate_id === corporate_id &&
      //   user.corporate_ids[0].status.toString() !== "0"
      // ) {
      //   return "Activo";
      // } else if (
      //   user.corporate_ids &&
      //   user.corporate_ids.length > 0 &&
      //   user.corporate_ids[0].corporate_id === corporate_id &&
      //   user.corporate_ids[0].status.toString() !== "1"
      // ) {
      //   return "Esperando aprobación";
      // } else if (
      //   user.old_corporate_ids.toString().includes(corporate_id.toString())
      // ) {
      //   return "Inactivo";
      // }
      return this.ES ? "Activo" : "Active";
    },
  },
  computed: {
    ...mapState("auth", ["wallet", "overdraft"]),
    ES() {
      return this.$vuetify.lang.current === "es";
    },
  },
};
</script>
<style lang="sass" scoped>
.vuedl-layout__closeBtn
  color: rgba(58, 53, 65, 0.54)

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    min-height: 100vh

a
  text-decoration: none

// .custom-margin
  // margin-top: -10px !important

// .test.v-btn:not(.v-btn--round).v-size--x-large
//   height: 55.22px !important

.custom-width
  width: 46%

.custom-margin-top
  margin-top: -10px

.dataTable
  tbody tr:nth-of-type(even)
    background-color: rgba(58, 53, 65, 0.04) !important

  tbody tr:hover
    background-color: rgba(0, 5, 34, 0.08) !important

  tbody tr
    border-bottom: hidden !important
</style>
